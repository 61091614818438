<template>
    <div class="card mb-3">
        <div class="card-header">
            <div class="row align-items-center justify-content-between">
                <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                    <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Payment Report</h5>
                </div>
                <div v-if="$auth.hasPermissions('create:manual_payments')"
                     class="col-12 col-sm-12 col-md-9 col-lg-8 col-xxl-6 d-flex align-items-center">
                    <input type="text"
                           class="form-control form-control-sm"
                           placeholder="Search..."
                           aria-label="Search"
                           v-model="search">
                    <button class="btn btn-sm btn-falcon-default text-nowrap ml-2" @click="exporting = true">
                        <i class="fa fa-download" aria-hidden="true" /> Filter & Export
                    </button>
                    <router-link v-if="$auth.hasSomePermission('read:bank_accounts', 'read:credit_cards')"
                                 :to="{ name: 'create-payment' }"
                                 class="btn btn-sm btn-falcon-default text-nowrap ml-2">
                        <i class="fas fa-plus" data-fa-transform="shrink-3" /> Create Payment
                    </router-link>
                </div>
            </div>
        </div>
        <div class="card-body p-0">
            <LoadingSpinner v-if="loading" class="my-5" />
            <LoadingError v-else-if="error" class="my-6" />
            <PaymentsTable v-else-if="payments.length > 0" :payments="payments" :filter="search" />
            <div v-else class="d-flex align-items-center flex-column p-3 my-7">
                <i class="fas fa-credit-card fa-2x opacity-50" />
                <span class="mt-3">No payments processed yet</span>
            </div>
        </div>
        <PaymentReportFilterModal :exporting.sync="exporting" />
    </div>
</template>

<script>
    import { getManualPaymentList } from '@/services/ManualPaymentService';
    import LoadingError from '@/components/LoadingError';
    import LoadingSpinner from '@/components/LoadingSpinner';
    import PaymentReportFilterModal from '@/components/PaymentReportFilterModal';
    import PaymentsTable from '@/components/PaymentsTable';

    export default {
        name: '',
        components: {
            LoadingError,
            LoadingSpinner,
            PaymentsTable,
            PaymentReportFilterModal,
        },
        data() {
            return {
                loading: true,
                error: null,
                payments: [],
                exporting: false,
                search: '',
            };
        },
        async mounted() {
            try {
                const { data } = await getManualPaymentList();
                this.payments = data;
            } catch (err) {
                this.error = err;
            } finally {
                this.loading = false;
            }
        },
    };
</script>
