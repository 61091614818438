<template>
    <Modal :shown="exporting" @close="$emit('update:exporting', false)">
        <template #default>
            <div class="modal-header">
                <h5 class="modal-title">Filter & Export Data</h5>
                <button class="close float-none" type="button" aria-label="Close" @click="$emit('update:exporting', false)">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group text-left ml-3">
                        <label for="from-date">From: </label>
                        <input v-model.lazy="query.from_date"
                               @keydown.enter.prevent
                               :max="query.to_date || currentDate"
                               id="from-date"
                               type="date"
                               class="form-control form-control-sm">
                    </div>
                    <div class="form-group text-left ml-3">
                        <label for="to-date">To: </label>
                        <input v-model.lazy="query.to_date"
                               @keydown.enter.prevent
                               :min="query.from_date || null"
                               :max="currentDate"
                               id="to-date"
                               type="date"
                               class="form-control form-control-sm">
                    </div>
                </div>
                <div class="form-group text-left ml-2">
                    <label for="customer-select">Customer</label> <label class="opacity-50">
                        (Optional)
                    </label>
                    <CustomerSelect v-model="query.customer_id"
                                    :customers="customers"
                                    :allow-empty="true"
                                    id="customer-select" />
                </div>
            </div>
            <div class="modal-footer">
                <FileDownloadButton :download-url="`/manual_payments/csv_export/` + queryParams"
                                    :file-name="`Payment Report.csv`"
                                    class="btn btn-sm btn-falcon-default">
                    Export to CSV
                </FileDownloadButton>
            </div>
        </template>
    </Modal>
</template>

<script>
    import CustomerSelect from '@/components/CreatePayment/CustomerSelect.vue';
    import FileDownloadButton from '@/components/FileDownloadButton';
    import { formatISO } from 'date-fns';
    import { getCustomerList } from '@/services/CustomerService';
    import Modal from '@/components/Modal';

    export default {
        name: 'PaymentReportFilterModal',
        components: {
            FileDownloadButton,
            Modal,
            CustomerSelect,
        },
        props: {
            exporting: { type: Boolean, default: false },
        },
        data() {
            return {
                loading: true,
                error: null,
                customers: [],
                query: {
                    from_date: null,
                    to_date: null,
                    customer_id: null,
                },
            };
        },
        computed: {
            selectedCustomer() {
                if (this.customerId) {
                    return this.customers.find(c => c.id === this.customerId);
                }
                return null;
            },
            currentDate() {
                return formatISO(new Date(), { representation: 'date' });
            },
            queryParams() {
                const queryString = Object.entries(this.query)
                    .filter(([, value]) => value)
                    .map(([key, value]) => key + '=' + value)
                    .join('&');

                return queryString && ('?' + queryString);
            },
        },
        async mounted() {
            try {
                const { data: customers } = await getCustomerList();
                this.customers = customers;
            } catch (error) {
                this.error = error;
                this.$alerts.danger('An unexpected error occurred',
                                    'Please contact support if this problem persists.');
            } finally {
                this.loading = false;
            }
        },
    };
</script>
